body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ================== */

.button {
  display: inline-block;
  padding: 2px 10px 5px 10px;
  text-decoration: none;
  color: white;
  border-radius: 5px;
  border: 2px;
  border-style: solid;
  font-size: 14px;
  margin: 2px 5px;
}

.button {
  transition-duration: 0.4s;
}

.button.small {
  padding: 1px 5px 2px 5px;
  font-size: 10px;
}

.button:hover {
  background-color: white; /* Green */
  color: black;
}

.button1 {background-color: #4CAF50; border-color: #4CAF50;} /* Green */
.button2 {background-color: #008CBA; border-color: #008CBA;} /* Blue */
.button3 {background-color: #f44336; border-color: #f44336;} /* Red */
.button4 {background-color: #e7e7e7; border-color: #e7e7e7;} /* Gray */
.button5 {background-color: #555555; border-color: #555555;} /* Black */

